<template>
    <div
        v-if="mission"
        class="route-container"
    >
        <b-container class="px-4 gradient" fluid>
            <progress-tree />
        </b-container>
        <b-container class="px-4" fluid>
            <b-row class="header-top text-left">
                <div class="pl-4">
                    <span class="text-profile-title-first">{{ mission.alias }}</span>
                </div>
            </b-row>
            <b-row>
                <b-col>
                    <slider
                        ref="missionRouteTaskCarousel"
                        class="task-carousel"
                        :options="carouselOptions"
                        @slide="slideChanged"
                    >
                        <slideritem
                            v-for="task in mission.tasks"
                            :key="task.id"
                            class="task-slide"
                        >
                            <task
                                :task="task"
                            />
                        </slideritem>
                    </slider>
                    <div
                        v-if="!hasBelowTwoItems(mission.tasks)"
                        class="arrow left"
                        @click="prevSlide"
                    >
                        <font-awesome-icon :icon="['fas','chevron-left']" />
                    </div>

                    <div
                        v-if="!hasBelowTwoItems(mission.tasks)"
                        class="arrow right"
                        @click="nextSlide"
                    >
                        <font-awesome-icon :icon="['fas','chevron-right']" />
                    </div>
                    <!-- <div v-show="!hasBelowTwoItems(mission.tasks)" class="carousel-pagination">
                        <div
                            v-for="(bullet, index) in mission.tasks"
                            :key="bullet.src"
                            class="bullet"
                            :class="{active: carouselIndex === index}"
                            @click="slideTo(index)"
                        />
                    </div> -->
                </b-col>
            </b-row>
        </b-container>
        <div v-if="mission.tasks.length === 0">Νο tasks found!</div>
    </div>
</template>

<script>
    import Task from '@/components/Task'
    import ProgressTree from '@/components/ProgressTree'
    import { slider, slideritem } from 'vue-concise-slider'
    export default {
        name: 'MissionRoute',
        components: {
            Task,
            ProgressTree,
            slider,
            slideritem
        },
        data() {
            return {
                carouselIndex: 0,
                carouselOptions: {
                    pagination: false
                }
            }
        },
        computed: {
            mission() {
                return this.$store.getters.getMission(
                    parseInt(this.$route.params.missionId)
                )
            },
            nextTaskIndex() {
                if (this.$store.getters.isPreviewMode) {
                    return 0
                }
                if (!this.mission) {
                    return 0
                }
                const nextTaskIndex = this.mission.tasks.findIndex(
                    task => task.next
                )

                return nextTaskIndex === -1 ? 0 : nextTaskIndex
            }
        },
        watch: {
            nextTaskIndex: {
                handler(nextTaskIndex) {
                    if (nextTaskIndex !== 0) {
                        this.$nextTick(() => {
                            this.slideTo(nextTaskIndex)
                        })
                    }
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.slideTo(this.nextTaskIndex)
            })
        },
        updated() {
            this.redirectOnInvalidMission()
        },
        methods: {
            redirectOnInvalidMission() {
                if (!(this.mission)) {
                    this.$router.push({ name: 'home' })
                }
            },
            prevSlide() {
                this.$refs.missionRouteTaskCarousel.$emit('slidePre')
            },
            nextSlide() {
                this.$refs.missionRouteTaskCarousel.$emit('slideNext')
            },
            hasBelowTwoItems(items) {
                return items && items.length < 2
            },
            slideChanged(data) {
                this.carouselIndex = data.currentPage
            },
            slideTo(num) {
                const renderedCarousel = this.$refs.missionRouteTaskCarousel
                if (renderedCarousel) {
                    this.carouselIndex = num
                    renderedCarousel.$emit('slideTo', num)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.route-container {
    padding: 1rem 0 2rem;
}

.task {
    outline: none;
}

.task-carousel {
    margin-top: 1rem;
    padding: 0 1rem;
}

.gradient {
    background: linear-gradient(rgb(255 0 0 / 0%), rgb(255 255 255 / 31%));
}

.header-top {
    margin-top: 1rem;
    font-size: 1.25rem;
}

.text-profile-title-first {
    font-weight: bold;
    color: var(--main-content-text);
}

.arrow {
    display: none;
    color: var(--logo-highlight-color);
    cursor: pointer;
    position: absolute;
    top: 50%;
}

.arrow.left {
    left: 0;
    padding: 0.5rem 1rem 0.5rem 0;
}

.arrow.right {
    right: 0;
    padding: 0.5rem 0 0.5rem 1rem;
}

.slider-container {
    white-space: normal;
    height: unset;
}

.slider-item {
    height: unset;
}

.task-slide {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    margin-right: 1.5rem;
    width: 100%;
    max-width: 280px;
    min-height: 460px;
}

.carousel-pagination {
    padding: 1.5rem 0;
    min-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--light-highlight-color);
    cursor: pointer;
}

.bullet + .bullet {
    margin-left: 1rem;
}

.bullet.active {
    background: var(--logo-highlight-color);
}

@media screen and(min-width: 768px) {
    .task-carousel {
        margin-top: 2rem;
    }

    .arrow {
        display: block;
    }

    // .carousel-pagination {
    //     display: none;
    // }
}

</style>
