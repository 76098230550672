<template>
    <div
        class="task-bg"
        :class="{'clickable': isClickable }"
        @click="goToTask()"
    >
        <img class="task-image" :src="task.imageUrl" alt="task image">
        <div
            class="task-content"
            :class="{'completed-overlay': task.completed}"
        >
            <span
                class="task-title"
                :class="{'completed-overlay': task.completed}"
            >
                <span>{{ task.alias }}</span>
                <span class="points-text">+ {{ task.points }} {{ $t('task.points') }}</span>
            </span>
            <span class="bottom-title">
                <span v-if="task.locked && remainingDaysToUnlock > 0" class="locked-text">
                    {{ $t('task.unlocksInNumberOfDays') }} {{ remainingDaysToUnlock }} {{ $t('task.unlockDaysText') }}
                </span>
                <img
                    v-if="task.locked"
                    class="locked-img"
                    src="@/assets/images/task-icons/locked_icon.png"
                    alt="locked image"
                >
                <span v-if="task.next">
                    <span class="btn start-button">{{ $t('task.start') }}</span>
                </span>
                <img
                    v-if="showCompleted"
                    class="completed-img"
                    src="@/assets/images/task-icons/completed_icon.png"
                    alt="completed image"
                >
                <div
                    v-else-if="showPlayAgain"
                    class="play-again-container"
                >
                    <img
                        class="completed-img"
                        src="@/assets/images/task-icons/play_again.png"
                        alt="completed image"
                    >
                    <span class="play-again">{{ $t('task.playAgain') }}</span>
                </div>
            </span>
            <span
                v-if="showPlayAgain"
                class="play-again-text"
            >
                {{ $t('task.playAgainText') }}
            </span>
        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    import moment from 'moment'

    export default {
        name: 'Task',
        mixins: [ config ],
        props: {
            task: {
                type: Object,
                required: true
            }
        },
        computed: {
            isPlayAgainEnabled() {
                return this.$store.getters.isPlayAgainEnabled
            },
            isClickable() {
                return (!this.task.completed && !this.task.locked) || (this.isPlayAgainEnabled && !this.isExcludedFromPlayAgain) || this.$store.getters.isPreviewMode
            },
            isExcludedFromPlayAgain() {
                return this.task.taskType.alias.startsWith('ACTION')
            },
            showPlayAgain() {
                return this.isPlayAgainEnabled && this.task.completed && !this.isExcludedFromPlayAgain
            },
            showCompleted() {
                return (this.task.completed && this.isExcludedFromPlayAgain) || !this.isPlayAgainEnabled
            },
            remainingDaysToUnlock() {
                if (this.task.locked && this.task.startDate) {
                    let diff = moment(this.task.startDate).startOf('day').diff(moment().startOf('day'), 'days')
                    return diff
                } else {
                    return 0
                }
            },
            taskPath() {
                if (this.task.id) {
                    return this.$store.getters.getTaskPath(this.$route.params.missionId, this.task.id)
                } else {
                    return this.$store.getters.getNextTaskPath(this.$route.params.missionId)
                }
            }
        },
        methods: {
            goToTask() {
                if (!this.isClickable) {
                    return
                }
                if (this.isClickable) {
                    this.$router.push({
                        path: this.taskPath
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}

.task-bg {
    height: 460px;
    position: relative;
}

.task-image,
.task-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.task-image {
    object-fit: contain;
}

.task-content {
    --background-overlay: rgb(0 0 0 / 2%);
    --completed-background-overlay: rgb(0 0 0 / 60%);

    display: flex;
    flex-direction: column;
    background: var(--background-overlay);
}

.task-content.completed-overlay {
    background: var(--completed-background-overlay);
}

.task-title {
    font-weight: 700;
    font-size: 20px;
    color: var(--white);
    min-height: 113px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 1rem 0;
    margin: 0 auto;
}

.task-title.completed-overlay {
    background: transparent;
}

.status-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
}

.info {
    background-color: var(--white);
    background-size: cover;
    color: var(--black);
}

svg.check {
    font-size: 2rem;
}

svg.check path {
    color: var(--white);
}

svg.lock {
    font-weight: 700;
    font-size: 20px;
}

svg.lock path {
    color: var(--error);
}

.bottom-title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.play-again-container {
    position: relative;
}

.play-again {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.play-again-text {
    font-size: 0.75rem;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.locked-img,
.completed-img {
    transform: translateY(2rem);
}

.completed-text {
    font-weight: 700;
    font-size: 1.25rem;
    color: var(--white);
    width: 100%;
}

.points-text {
    font-weight: 700;
    font-size: 14px;
    color: var(--white);
    margin-bottom: 0;
}

.locked-text {
    font-weight: 700;
    color: var(--black);
    font-size: 16px;
    background: var(--white);
    padding: 10px 20px;
}

.start-button {
    background: var(--white);
    padding: 10px 20px;
    font-size: 1.1rem;
    border-radius: 15px;
    width: 150px;
    color: var(--black);
    font-weight: 700;
    transform: translateY(20%);
    text-transform: uppercase;
    box-shadow: 0 0 40px var(--black);
}

</style>
